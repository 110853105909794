<template>
  <v-dialog v-model="openComments" max-width="1200px">
    <v-card>
    <v-tabs
      color="deep-purple accent-4"
      centered
      style="padding-top: 3ch;"
    >
   
      <v-tab>Comment History</v-tab>
      <v-tab>Kaizer Documents</v-tab>
      <v-tab>AMLA Documents</v-tab>
    
      <v-tab-item
      >
          <v-card>
            <v-card-title>
              <span class="text-h6">Comments Table</span>
              <v-spacer></v-spacer>
              <v-btn icon  @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="spComments.logs"
                :calculate-widths="true"
                >
                <template v-slot:item.comment="{ item }">
                  <span :style="{ color: item.comment ? 'black' : '#7E818B' }">
                    {{ item.comment || item.message }}
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="closeDialog">Close</v-btn>
            </v-card-actions>
          </v-card>
      </v-tab-item>

      <v-tab-item
      >
          <v-card>
            <v-card-title>
              <span class="text-h6">Kaizer Documents</span>
              <v-spacer></v-spacer>
              <v-btn icon  @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headersKaizer"
                :items="spKaizer"
                :calculate-widths="true"
                >
                <template #item.timestamp="{ item }">
                  {{ formatDate(item.timestamp) }}
                </template>
                <template #item.url="{ item }">
                <v-btn
                  icon
                  color="primary"
                  @click="retrieveUploaded(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="closeDialog">Close</v-btn>
            </v-card-actions>
          </v-card>
      </v-tab-item>

      <v-tab-item
      >
          <v-card>
            <v-card-title>
              <div>
              <span class="text-h6">AML Details</span>
              <br>
                <span class="text-h6">Current Rating: <span style="color: red;">{{ amlRating }}</span></span>
                <br>
              <span class="text-h6">Current Profile: <span style="color: red;">{{ amlProfile }}</span></span>
              </div>
              
              <v-spacer></v-spacer>
              <v-btn icon  @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headersAML"
                :items="spAml"
                :calculate-widths="true"
                >
                <template #item.timestamp="{ item }">
                  {{ formatDate(item.timestamp) }}
                </template>
                <template #item.url="{ item }">
                <v-btn
                  icon
                  color="primary"
                  @click="retrieveUploaded(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="closeDialog">Close</v-btn>
            </v-card-actions>
          </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseISO, format } from 'date-fns';


export default {
  props: {
    openComments: {
      type: Boolean,
      required: true,
    },
    itemId: {
      type: [String, Number],
      required: true,
    },
   
  },
  data() {  
    return {
      tab: 1,
      spComments: [],
      spKaizer: [],
      spAml: [],
      amlRating: '',
      amlProfile: '',
      headers: [
            {
                text: 'User',
                sortable: true,
                value: 'user'
            },
            {
                text: 'Date',
                sortable: true,
                value: 'datetime'
            },
            {
                text: 'Type',
                sortable: true,
                value: 'type'
            },
            {
                text: 'Comments',
                value: 'comment'
            },
        ],
        headersKaizer: [
            {
                text: 'Date',
                sortable: true,
                value: 'timestamp'
            },
            {
                text: 'File Name',
                sortable: true,
                value: 'title'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'url',
            },
        ],
        headersAML: [
            {
                text: 'Date',
                sortable: true,
                value: 'timestamp'
            },
            {
                text: 'File Name',
                sortable: true,
                value: 'title'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'url',
            },
        ],
    };
  },

  watch: {
  //run get Comments everytime user clicks the view button (Runs when the modal is open)
  openComments(val) {
    if (val) {
      this.getComments();
      this.getDetails();
    }
  },
},
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
    }),
  },
  methods: {
    //Run the API to get all the comments of the specific SP
    async getComments() {
      try {
        const res = await this.$store.dispatch('appHistory/doGetComments', this.itemId);
        this.spComments = res.data.result
      } catch (error) {
        console.error('Failed to fetch comments:', error);
      }
    },
    async getDetails () {
		const resSP = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.itemId)
    this.spKaizer = JSON.parse(resSP.data.result[0].sp_primary_details.upload_kaizer)
    this.spAml = JSON.parse(resSP.data.result[0].sp_primary_details.upload_amla)
    this.amlProfile = resSP.data.result[0].sp_primary_details.amla_risk_profile
    this.amlRating = resSP.data.result[0].sp_primary_details.amla_risk_rating

  },
    closeDialog() {
      this.$emit('update:openComments', false); //close the comments section
      this.spComments = [] //Reset Table
      this.spKaizer = [] //Reset Table
      this.spAml = [] //Reset Table
      this.amlProfile = ''
      this.amlRating = ''
    },
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
    async retrieveUploaded (item) {
			const payload = {
				file: item.url
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			} else if (extension === 'xlsx') {
				//---------------------------- OPENING OF AML FORM IF XLSX ----------------------------------
				blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }) 
			} else if (extension === 'csv') {
				//---------------------------- OPENING OF AML FORM IF CSV ----------------------------------
				blob = new Blob([file], { type: 'text/csv' }) 
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
  },
 
};
</script>
