<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
    <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="salesProspecting"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id })" :style="{ color: getAccountNameColor(item) }">
                                    {{ item.sp_primary_details.account_name }}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <template v-slot:[`item.action`] = {item}>
                                <div>
                                  <!-- Open Comments per SP -->
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="doOpenComments(item.id)"
                                        >
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>  
                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
          </v-container>
          <appHistoryTable :openComments="openComments" :itemId="selectedItemId" @update:openComments="updateDialogState"/>
        </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'
import appHistoryTable from '../components/modules/LogsHistory/LogsHistoryTable.vue'

export default {
  components: {
		appHistoryTable:appHistoryTable
	},
  data() {
    return {
        search: '',
        fab: false,
        salesProspecting: [],
        newSalesProspect: [],
        openComments: false,
        selectedItemId: '',
      headers: [
            {
                text: 'Account Name',
                sortable: true,
                value: 'sp_primary_details.account_name'
            },
            {
                text: 'BP ID Number',
                sortable: true,
                value: 'bp_idno'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'prospective_number'
            },
            {
                text: 'Owner',
                sortable: true,
                value: 'created_by'
            },
            {
                text: 'Date Created',
                sortable: true,
                value: 'created_at'
            },
            {
                text: 'Profile',
                sortable: true,
                value: 'profile'
            },
            {
                text: 'Approval Status',
                sortable: true,
                value: 'status'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        greenStatus: ['Initial Evaluation Complete','Onboarding Approved','Initial Existing Complete','Existing Complete','Merged (IS)','Existing Complete (IS)'],
        orangeStatus: ['pending','Inital Evaluation Committee Approval Pending','Initial Evaluation Committee Approved','Onboarding Approval Pending','existing','Pending (Presentation)','For negotiation','Accepts Partnership','Evaluation (Returned)','Termination Pending','Deletion Pending','Initial Evaluation Committee Approval Pending','Onboarding (Returned)','Validation (Returned)','Existing (Validation Approval Pending)'],
        redStatus: ['Scorecard Failed','Not accepted','Archived','Existing (Temporarily Terminated)','Terminated','Evaluation (Hanging)'],
    };
  },
  computed: {
    getAccountNameColor() {
        return (item) => {
            if (this.greenStatus.includes(item.status)) {
                return 'green'
            } else if (this.orangeStatus.includes(item.status)) {
                return 'orange'
            } else if (this.redStatus.includes(item.status)) {
                return 'red'
            } 
            else {
                return 'black'
            }
        }
    },
    ...mapGetters({
      currUser: 'auth/currUser'
    })
    
  },
  async mounted () {
    //Get the list of all PRPs
    const res = await this.$store.dispatch('salesProspecting/doGetAllListSalesProspecting')
    this.salesProspecting = res.data.result
  },
  methods: {
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
    //Open Comments when icon is clicked
    doOpenComments(id){
    this.openComments = true;
    this.selectedItemId = id
  },
  //Close Comments
    updateDialogState(val) {
      this.openComments = val;
  }
}
};
</script>
